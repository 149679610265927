
@font-face {
  font-family: "SFPro";
  src: url("./assets/fonts/SFProDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SFPro";
  src: url("./assets/fonts/SFProDisplay-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "SFPro";
  src: url("./assets/fonts/SFProDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'SFPro';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #152241;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

